export default
`#### Marine eutrophication (kg N eq.)

We can obtain the *Marine eutrophication* from the following KPIs by multiplying them by the following conversion factors and then, sum all the results:

|                                           | Conversion factor|
|:------------------------------------------|-----------------:|
|**Biomass produced**(Tn) only for seabass  |               103|
|**Biomass produced**(Tn) only for seabream |               194|
|**Feed**(Tn):                              |               4.1|
|**Fuel, burned in vessel**(L):             |            0.0263|
|**Electricity, european average**(Kwh):    |          0.000421|

<br>
For example, we have:

- 1000 Tn of biomass produced of seabass.
- 2500 Tn of feed.
- 250 L Of fuel.
- 250 Kwh of electricity.

The operation will be:
$$
    (1,000 \\cdot 103) + (2,500 \\cdot 4.1) + (250 \\cdot 0.0263) + (250 \\cdot 0.000421)
$$
$$
    = 0.10525 + 10250 + 103000 + 6.575
$$
$$
    = 113,256.68
$$

<br>

*Sources* : MedAid project, Ecoinvent V3, Garcia et al. 2019 *`;